import React from 'react';
import { Auth } from 'aws-amplify';
import { useRecoilState } from 'recoil';

import authenticatedState from '../states/authenticatedState';
import userState from '../states/userState';

const withAuth = Component => props => {
  const [authenticated, setAuthenticated] = useRecoilState(authenticatedState);
  const [user, setUser] = useRecoilState(userState);
  const setAuthenticatedStable = React.useCallback(setAuthenticated, [setAuthenticated]);
  const setUserStable = React.useCallback(setUser, [setUser]);

  React.useEffect(() => {
    let mounted = true;

    Auth.currentAuthenticatedUser()
      .then(user => {
        if (process.env.NODE_ENV === 'development') {
          console.log(user);
        }
        if (mounted && user) {
          setAuthenticatedStable(true);
          setUserStable(user);
        }
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
        setAuthenticatedStable(false);
        setUserStable(null);

        const authUrl = process.env.THESEUS_URL || 'https://theseus.smsoftware.io';
        const redirectUrl = window.location.origin;
        window.open(`${authUrl}?redirectUrl=${encodeURIComponent(redirectUrl)}`, '_self');
      });

    return () => {
      mounted = false;
    };
  }, [setAuthenticatedStable, setUserStable]);

  if (!authenticated) {
    return null;
  }

  return <Component {...props} user={user} />;
};

export default withAuth;
