import React from 'react';
import { useQuery, gql } from '@apollo/client';

const withUserInfo = Component => props => {
  const { user } = props;
  const { data } = useQuery(
    gql`
      query GetUser($username: String!) {
        getUser(username: $username) {
          firstName
          lastName
          roles
        }
      }
    `,
    { variables: { username: user.username } }
  );

  if (!data) return null;

  return <Component {...props} userInfo={data.getUser} />;
};

export default withUserInfo;
