import React from 'react';
import PropTypes from 'prop-types';

import A2HS from '../alerts/A2HS';
import SWUpdate from '../alerts/SWUpdate';
import NotAuthorized from './NotAuthorized';
import Sidebar from './Sidebar';

const Layout = ({ children, metadata, userInfo }) => (
  <>
    {userInfo.roles.map(role => role.toUpperCase()).includes('ADMIN') ? (
      <Sidebar title={metadata.title}>{children}</Sidebar>
    ) : (
      <NotAuthorized userInfo={userInfo} />
    )}
    <A2HS />
    <SWUpdate />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  metadata: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  userInfo: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default Layout;
