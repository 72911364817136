import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';

const NotAuthorized = ({ userInfo }) => (
  <div className='flex items-center justify-center px-4 py-12 min-h-screen bg-white sm:px-6 lg:px-8'>
    <main className='w-full max-w-md space-y-8'>
      <StaticImage
        src='../../images/smsoftware-icon.png'
        className='mx-auto w-auto h-12'
        alt='Signal Mountain Software'
        placeholder='blurred'
        quality={100}
        formats={['AUTO', 'PNG']}
        layout='fixed'
        width={64}
      />
      <div className='space-y-4'>
        <h2 className='text-center text-smsoftware-blue text-3xl font-extrabold'>
          Sorry, {userInfo.firstName} {userInfo.lastName}!
        </h2>
        <h3 className='text-justify text-smsoftware-blue text-2xl font-semibold'>
          You must be an AVA Admin to access this application...
        </h3>
      </div>
    </main>
  </div>
);

NotAuthorized.propTypes = {
  userInfo: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default NotAuthorized;
